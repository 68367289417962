const initState = {
  terms: false,
  privacy: false,
  partners: false,
}

// Action names
const TOGGLE_MODAL = 'TOGGLE_MODAL'

// Action Handlers
export const toggleModal = (modal) => (dispatch) => {
  dispatch({ type: TOGGLE_MODAL, payload: modal })
  return true
}

// Reducer
export default (state = initState, action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      let toggle = !state[action.payload]
      return {
        [action.payload]: toggle,
      }
    default:
      return state
  }
}
