import axios from 'axios'

export const formatPhoneNumber = (phoneNumberString) => {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return match[1] + match[2] + match[3]
  }
  return null
}

export const validateEmail = (email) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const submitApplication = (type, data, src) => {
  /*
        Types + Val Options:
            19 -> Life
                use_tobacco: Y,N
                gender: M,F
                insurance_type: final_expense, term
                insurance_amount: 50000
            21 -> medicare
                gender: M,F
                use_tobacco: Y,N
                age: Decimal 2
            27 -> health
                gender: Male, Female
                age: Int
                use_tobacco: Yes, No
                household_size: 1
                currently_insured: Yes, No

        Global Options:
            DOB: MM/DD/YYYY
            dob_month: Varchar
            dob_day: Varchar
            dob_year: Varchar
    */
  let phone = formatPhoneNumber(data.phone)
  let zip = data.zip.replace(/\D/g, '').slice(0, 5)

  if (zip.length !== 5) {
    return Promise.reject(new Error('Zip code must be 5 digits'))
  }
  if (phone.length !== 10) {
    return Promise.reject(new Error('Phone number must include your area code'))
  }
  if (!validateEmail(data.email)) {
    return Promise.reject(new Error('Please provide a valid email address'))
  }

  // Format the data how we need it
  const date11MonthsAgo = new Date()
  const month = date11MonthsAgo.getMonth()
  date11MonthsAgo.setMonth(month - 11)
  const date11MonthsAgoFormatted = date11MonthsAgo.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })

  const date3DaysAgo = new Date()
  const day = date3DaysAgo.getDate()
  date3DaysAgo.setDate(day - 3)
  const date3DaysAgoFormatted = date3DaysAgo.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })

  const randomDate = (date1, date2) => {
    const randomValueBetween = (min, max) => Math.random() * (max - min) + min
    const date1GetTime = new Date(date1).getTime()
    const date2GetTime = new Date(date2).getTime()
    if (date1GetTime > date2GetTime) {
      return new Date(randomValueBetween(date2GetTime, date1GetTime)).toLocaleDateString(
        'en-US',
        {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        },
      )
    } else {
      return new Date(randomValueBetween(date1GetTime, date2GetTime)).toLocaleDateString(
        'en-US',
        {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        },
      )
    }
  }

  const generatedRandomDateFromRange = randomDate(
    date11MonthsAgoFormatted,
    date3DaysAgoFormatted,
  )

  const datePlus12Months = new Date(generatedRandomDateFromRange)
  const m = datePlus12Months.getMonth()
  datePlus12Months.setMonth(m + 12)
  const generatedRandomDatePlus12MonthsFormatted = datePlus12Months.toLocaleDateString(
    'en-US',
    {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    },
  )

  let postData = {
    TYPE: 31,
    first_name: data.first,
    last_name: data.last,
    primary_driver: `${data.first} ${data.last}`,
    email: data.email,
    phone_1: phone,
    home_phone: phone,
    address: data.address,
    address_1: data.address,
    zip: zip,
    Zip: zip,
    zip_code: zip,
    licensed_state: data.licensed_state,
    gender: data.gender === 'male' ? 'M' : 'F',
    dob_month: parseInt(data.month),
    dob_day: parseInt(data.day),
    dob_year: parseInt(data.year),
    birthdate: `${parseInt(data.month)}/${parseInt(data.day)}/${parseInt(data.year)}`,
    multiple_policy_discount: data.additional_vehicles,
    multi_car: data.additional_vehicles,
    additional_vehicles: data.additional_vehicles,
    additional_drivers: data.additional_drivers === 'Yes' ? 'Yes' : 'No',
    consent_language:
      'By clicking the View Plans button and submitting this form, I agree that I am 18+ years old and agree to the Privacy Policy and Terms & Conditions. By clicking the View Plans button and submitting this form, I provide my signature giving express consent to receive marketing communications via automated telephone dialing systems, artificial or pre-recorded voices, emails, live phone calls, pre-recorded calls, postal mail, text messages via SMS or MMS and other forms of communication regarding offers of auto insurance, home insurance, life insurance, health insurance or other products from Cege Media and/or one or more of it’s marketing partners and agents to the number(s) and/or email I provided, including a mobile phone, even if I am on a state or federal Do Not Call and/or Do Not Email registry. The list of companies participating are subject to change. I understand my wireless carrier may impose charges for calls or texts.I understand that my consent to receive communications is not a condition of purchase and may revoke my consent at any time by contacting us via 1-833-259-7339 or email at opt out@CEGE.com so that you may be placed on our do-not-call and/or our do-not-email list. To receive quotes without providing consent, please call at 1-833-851-0279.',
    Age: Math.round(data.age),
    LeadiD: data.leadid,
    insured_past_30_days: data.autoInsurance ? 'Yes' : 'No',
    phone_last_4: phone.slice(-4),
    currently_insured: data.existing === 'No' ? 'No' : 'Yes',
    insured_since: data.existing === 'Yes' ? generatedRandomDateFromRange : null,
    policy_expiration_date:
      data.existing === 'Yes' ? generatedRandomDatePlus12MonthsFormatted : null,
    // term_length: "null",
    veh_year: data.vehicle_year,
    veh_make: data.vehicle_make,
    veh_model: data.vehicle_model,
    // vehicle_trim: data.vehicle_trim,
    // vehicle_year_second: data.vehicle_year_second,
    // vehicle_make_second: data.vehicle_make_second,
    // vehicle_model_second: data.vehicle_model_second,
    // vehicle_trim_second: data.vehicle_trim_second,
    drivers_license: data.license,
    drivers_status: data.license === 'Yes' ? 'Active' : 'Suspended',
    license_status: data.license === 'Yes' ? 'Active' : 'Suspended',
    suspended_or_revoked: data.license === 'Yes' ? 'No' : 'Yes',
    // autoInsurance: data.autoInsurance,
    insurance_company: data.autoInsuranceCompany
      ? data.autoInsuranceCompany
      : '(Not Listed)',
    // autoInsuranceCompanyLength: data.autoInsuranceCompanyLength,
    marital_status: data.married,
    credit_rating: data.credit,
    // education: data.education,
    home_owner: data.homeowner === 'Yes' ? 'Yes' : 'No',
    current_residence: data.homeowner === 'Yes' ? 'Own' : 'Rent',
    years_at_current_residence: data.homeowner === 'Yes' ? '3' : '1',
    // insuranceHomeRent: data.insuranceHomeRent,
    // accidents: data.accidents,
    filing_required: data.sr22 === 'Yes' ? 'Yes' : 'No',
    tcpa_site: 'https://autoinsurancecompanion.com/',
    landing_page: 'https://autoinsurancecompanion.com/',
    violations: data.violations,
    tickets_accidents_claims_past_3_years: data.tickets_accidents_claims_past_3_years,
    dui_dwi: data.dui_dwi,
    // driver2_first_name: data.driver2_first_name,
    // driver2_last_name: data.driver2_last_name,
    // driver2_gender: data.driver2_gender,
    // driver2_birthdate: data.driver2_birthdate,
    // driver2_birthdate_year: data.driver2_birthdate_year,
    // driver2_birthdate_day: data.driver2_birthdate_day,
    // driver2_birthdate_month: data.driver2_birthdate_month,
    Trusted_Form_Token:
      data.Trusted_Form_Token && data.Trusted_Form_Token.split('.com/')[1],
    Trusted_Form_Cert_URL: data.Trusted_Form_Cert_URL,
    ...(data.Sub_ID && { Sub_ID: data.Sub_ID }),
    ...(data.Pub_ID && { Pub_ID: data.Pub_ID }),
  }

  // Attach SRC if we have a value in store
  if (src !== '') {
    postData['SRC'] = src

    if (type === 'medicare' && data.other) {
      postData['SRC'] = src.replace('-Auto', '-Med')
    }

    if (type === 'life' && data.other) {
      postData['SRC'] = src.replace('-Auto', '-Life')
    }
  } else {
    if (type === 'medicare' && data.other) {
      // postData['SRC'] = 'CMI_MedicareCompanion_Organic'
      postData['SRC'] = 'CMI_AutoInsuranceCompanion_Organic-Med'
    } else {
      // postData['SRC'] = 'CMI_AutoInsuranceCompanion_Organic-Auto'
      postData['SRC'] = 'CMI_AutoInsuranceCompanion_Organic-Auto'
    }
    if (type === 'life' && data.other) {
      postData['SRC'] = 'CMI_AutoInsuranceCompanion_Organic-Life'
    }
  }

  if (type === 'medicare' && data.other) {
    postData['TYPE'] = 21
    postData['use_tobacco'] = 'N'
    postData['insurance_amount'] = 20000
  }

  if (type === 'life' && data.other) {
    postData['TYPE'] = 19
    postData['use_tobacco'] = 'N'
    postData['insurance_amount'] = 20000
    postData['term_length'] = '15'
  }

  // Determine insurance type and term length
  if (postData['Age'] < 50) {
    postData['insurance_type'] = 'term'
    postData['term_length'] = '15'
  } else if (
    postData['Age'] >= 50 &&
    postData['Age'] <= 55 &&
    postData['insurance_amount'] <= 50000
  ) {
    postData['insurance_type'] = 'final_expense'
  } else if (
    postData['Age'] >= 50 &&
    postData['Age'] <= 55 &&
    postData['insurance_amount'] > 50000
  ) {
    postData['insurance_type'] = 'term'
    postData['term_length'] = '15'
  } else if (postData['Age'] >= 55) {
    postData['insurance_type'] = 'final_expense'
  }

  // delete postData['SRC'];

  // Bypass the API call and just return the data format
  // Later we should make a param in the endpoint that
  // allows us to overwrite the test value
  // if (process.env.NODE_ENV === 'development') {
  //   return Promise.resolve(postData)
  // }

  const submitEndpointUrl =
    process.env.NODE_ENV === 'development'
      ? 'https://customer-dev.hivehq.co/v1/bobderdoo-lead-creation'
      : 'https://customer.hivehq.co/v1/bobderdoo-lead-creation'

  return axios
    .post(submitEndpointUrl, postData)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
